// src/pages/NotFound.js
import React from 'react';

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-gray-800">
      <h1 className="text-4xl font-bold mb-4">404 - Sayfa Bulunamadı</h1>
      <p className="text-xl">Aradığınız sayfa mevcut değil.</p>
    </div>
  );
}

export default NotFound;