import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import RestaurantDropdown from '../components/RestaurantDropdown';
import Navbar from '../components/Navbar';
import NotFound from './NotFound';
import data from '../utils/Data';

function RestaurantPage() {
  const { restaurant_name } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dbData, setDbData] = useState(null);
  const [supabase, setSupabase] = useState(null);
  const [supabase_url, setSupabaseUrl] = useState(null);
  const [supabase_anon_key, setSupabaseAnonKey] = useState(null);

  useEffect(() => {
    setDbData(data);
    setIsLoading(false);
    setRestaurant(data.find(restaurant => restaurant.name.toLowerCase().replace(/ /g, '-') === restaurant_name));
  }, []);

  if (isLoading) {
    return (
      <div className="bg-siteBG min-h-screen flex justify-center items-center">
        <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
        </div>
      </div>
    );
  }

  else if (!restaurant) {
    return <NotFound />;
  }

  return (
    <div>
      <div className='flex flex-col md:flex-row items-center justify-between flex-wrap text-white bg-siteBG'>
        <Navbar />
        <p className='text-green-500 text-center md:text-right md:pr-20 text-2xl md:text-5xl font-bold font-[Roboto] pt-5 md:pt-10'>{restaurant.name}</p>
      </div>
      <div className="flex flex-col items-center justify-start md:px-20 px-5 md:py-10 py-5 w-full bg-siteBG min-h-screen">
        <RestaurantDropdown name={restaurant.name} menu={restaurant.menu} status={restaurant.status} open_hours={restaurant.open_hours} tel={restaurant.tel} db={dbData} admin={false} id={restaurant.id} subpage={true} supabase_url={supabase_url} supabase_anon_key={supabase_anon_key} />
      </div>
    </div>
  );
}

export default RestaurantPage;