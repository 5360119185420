import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";

import Navbar from "../components/Navbar";
import RestaurantDropdown from "../components/RestaurantDropdown";
import Search from "../components/Search";
import data from "../utils/Data";

function Home() {  
  const [supabase, setSupabase] = useState(null);  
  const [isLoading, setIsLoading] = useState(true); // Loading state is used to prevent rendering before database call.
  const [supabaseData, setSupabaseData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [supabase_url, setSupabaseUrl] = useState(null);
  const [supabase_anon_key, setSupabaseAnonKey] = useState(null);
  useEffect(() => {
    setSupabaseData(data);
    setFilteredData(data);
    setIsLoading(false);
  }, []);

  

  const handleSearch = (query) => {
    if (!query) {
      setFilteredData(supabaseData);
      return;
    }

    const lowerCaseQuery = query.toLowerCase();
    const filtered = supabaseData.map(restaurant => {
      const filteredMenu = {};

      // Check if the query matches the restaurant name
      if (restaurant.name.toLowerCase().includes(lowerCaseQuery)) {
        return restaurant;
      }

      Object.keys(restaurant.menu).forEach(menuCategoryKey => {
        const menuCategory = restaurant.menu[menuCategoryKey];

        // Check if the query matches the menu category
        if (menuCategoryKey.toLowerCase().includes(lowerCaseQuery)) {
          filteredMenu[menuCategoryKey] = menuCategory;
        } else if (menuCategory && menuCategory["Ürünler"]) {
          // Normal menu structure
          const filteredItems = menuCategory["Ürünler"].filter(item => item && item.toLowerCase().includes(lowerCaseQuery));
          if (filteredItems.length > 0) {
            filteredMenu[menuCategoryKey] = {
              ...menuCategory,
              "Ürünler": filteredItems,
              "Fiyatlar": menuCategory["Fiyatlar"] ? menuCategory["Fiyatlar"].filter((_, index) => filteredItems.includes(menuCategory["Ürünler"][index])) : [],
              "Bilgi": menuCategory["Bilgi"] ? Object.fromEntries(Object.entries(menuCategory["Bilgi"]).filter(([key]) => filteredItems.includes(key))) : {}
            };
          }
        } else if (menuCategory) {
          // Table menu structure
          const filteredKeys = Object.keys(menuCategory).filter(key => key !== "Kategoriler" && key !== "Bilgi" && key.toLowerCase().includes(lowerCaseQuery));
          if (filteredKeys.length > 0) {
            filteredMenu[menuCategoryKey] = {
              ...menuCategory,
              ...Object.fromEntries(filteredKeys.map(key => [key, menuCategory[key]]))
            };
          }
        }
      });

      return {
        ...restaurant,
        menu: filteredMenu
      };
    }).filter(restaurant => Object.keys(restaurant.menu).length > 0 || restaurant.name.toLowerCase().includes(lowerCaseQuery));

    if (filtered.length === 0) {
      setFilteredData(null);
    } else {
      setFilteredData(filtered);
    }
  };

  if (isLoading) {
    return(
      <div className="bg-siteBG min-h-screen flex justify-center items-center">
        <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
        </div>
      </div>
    )
  }

  const restaurants = filteredData ? filteredData.map((current_restaurant) => {
    return <RestaurantDropdown key={current_restaurant.id} name={current_restaurant.name} menu={current_restaurant.menu} status={current_restaurant.status} open_hours={current_restaurant.open_hours} tel={current_restaurant.tel} db={supabaseData} admin={false} id={current_restaurant.id} subpage={false} supabase_url={supabase_url} supabase_anon_key={supabase_anon_key} />
  }) : <div className="text-center text-gray-500">Aradığınız kriterlerde restoran/menü/ürün bulunamadı.</div>;

  return (
    <div className="App">
      <div className="flex flex-col bg-siteBG justify-start text-left">
        <Navbar />
        <Search onSearch={handleSearch} />
      </div>
      <div className="flex flex-col items-center justify-start md:px-20 px-5 py-5 w-full bg-siteBG min-h-screen">
        {restaurants}
      </div>
    </div>
  );
}

export default Home;