import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/Home';


import RestaurantPage from './pages/RestaurantPage';
import NotFound from './pages/NotFound';  

if (window.location.href === 'https://www.iytesepeti.com/' || window.location.href === 'https://www.iytesepeti.com/') {
  window.location.replace('https://iytesepeti.com/');
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />


        <Route path="/menu/:restaurant_name" element={<RestaurantPage />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined pages */}
      </Routes>
    </Router>
  );
}

export default App;