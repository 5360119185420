import React, { useState } from 'react';

const Search = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    onSearch(value);
  };

  const handleClearInput = () => {
    setQuery('');
    onSearch('');
  };

  return (
    <div className="max-w-full px-5 md:px-20 pt-5 relative">
      <div className="relative w-full">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Menüde ara..."
          className="w-full px-4 py-2 md:py-3 border bg-siteBG text-white border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
        />
        {query && (
          <button
            onClick={handleClearInput}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white hover:text-gray-600 focus:outline-none"
          >
            &#x2715;
          </button>
        )}
      </div>
    </div>
  );
};

export default Search;