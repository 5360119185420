const data = [
  {
    "id": 1,
    "name": "Atölye Plus(+++) & Atabey Dürümmatik",
    "menu": {
      "Pizzamatik | Yeni": {
        "Ürünler": [
          "Margarita Pizza + Patates",
          "Mushroom Pizza + Patates",
          "Vegetable Pizza + Patates",
          "Atolye Karışık Pizza + Patates",
          "Füme Kaburga Pizza + Patates",
          "Pizza Pepperoni + Patates",
          "Polo Pizza + Patates",
          "4 Peynirli Pizza + Patates",
          "Mexican Pizza + Patates",
          "Ton Balıklı Pizza + Patates",
          "Kavurmalı Pizza + Patates"
        ],
        "Fiyatlar": [
          "220",
          "230",
          "255",
          "290",
          "320",
          "285",
          "285",
          "255",
          "295",
          "320",
          "320"
        ]
      },
      "Dürümmatik | Yeni": {
        "Ürünler": [
          "Chicken Adana Dürüm (Tavuk 160gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Chicken Adana Porsiyon (Tavuk 160gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Tavuk Şiş Dürüm (Tavuk 160gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Tavuk Şiş (1,5) Dürüm (Tavuk 240gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Tavuk Şiş Porsiyon (Tavuk 320gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Adana Dürüm (Et 100gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Adana Dürüm(1,5) (Et 150gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Adana Porsiyon (Et 200gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Urfa Dürüm (Et 100gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Urfa Dürüm(1,5) (Et 150gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Urfa Porsiyon (Et 200gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Kuşbaşı Dürüm(1,5) (Et 150gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Kuşbaşı Porsiyon (Et 300gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Et Şiş Dürüm (Et 100gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Et Şiş Dürüm(1,5) (Et 150gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "Et Şiş Porsiyon (Et 300gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "ÇiğKöfte Porsiyon+Eker Büyük Ayran+Eker Tatlı",
          "Kuşbaşı Dürüm (Et 100gr)+Patates+Eker Büyük Ayran+Eker Tatlı",
          "ÇiğKöfte Dürüm+Eker Büyük Ayran+Eker Tatlı"
        ],
        "Fiyatlar": [
          "160",
          "200",
          "160",
          "230",
          "290",
          "170",
          "240",
          "300",
          "170",
          "240",
          "300",
          "290",
          "350",
          "200",
          "290",
          "350",
          "170",
          "200",
          "120"
        ]
      },
      "Çılgın Dürümler": {
        "Ürünler": [
          "Ballı Hardal Soslu Tavuk Dürüm + Patates+Eker Büyük Ayran",
          "BBQ Soslu Tavuk Dürüm + Patates+Eker Büyük Ayran"
        ],
        "Fiyatlar": [
          "150",
          "150"
        ]
      },
      "Kanka Menü": {
        "Ürünler": [
          "Kanka Menü + 2 Eker Büyük Ayran"
        ],
        "Fiyatlar": [
          "350"
        ]
      },
      "Burgerler": {
        "Ürünler": [
          "Hamburger (Et 120gr)",
          "Cheese Burger (Et 120gr)",
          "Double Burger (Et 240gr)",
          "Dana Kaburga Füme Hamburger (Et 120gr)",
          "Chicken Burger (Tavuk 120 gr)",
          "BBQ Mushroom Burger (Et 120gr)"
        ],
        "Fiyatlar": [
          "230",
          "240",
          "320",
          "260",
          "150",
          "230"
        ]
      },
      "Wrapler": {
        "Ürünler": [
          "Tavuk Wrap",
          "Köfte Wrap",
          "Tavuk Şinitzel Wrap"
        ],
        "Fiyatlar": [
          "140",
          "200",
          "140"
        ]
      },
      "Makarnalar": {
        "Ürünler": [
          "Alfredo Penne",
          "Köri Penne",
          "Pesto Penne",
          "Mantarlı, Biberli Tavuklu Penne",
          "Ton Balıklı Penne",
          "Penne Arabiata"
        ],
        "Fiyatlar": [
          "170",
          "180",
          "180",
          "170",
          "195",
          "170"
        ],
        "Bilgi": {
          "Penne Arabiata": "Acılı domates sos, kuru domates, mısır, siyah zeytin"
        }
      },
      "Dürümler": {
        "Ürünler": [
          "Tavuk Döner Dürüm + Patates + Eker Büyük Ayran",
          "Kavurmalı Dürüm (150gr Et) + Patates + Eker Büyük Ayran",
          "Tavuk Döner Dürüm",
          "Kavurmalı Dürüm (150gr Et)"
        ],
        "Fiyatlar": [
          "160",
          "260",
          "150",
          "250"
        ]
      },
      "Kudret Hanım'dan Tostlar": {
        "Ürünler": [
          "Kavurmalı Kaşarlı Tost",
          "Karışık Tost",
          "Sucuklu Tost",
          "Kaşarlı, Cheddar Peynirli Tost",
          "Tavuk Şinitzel, Kaşarlı Tost",
          "Mantarlı, Kaşarlı Tost",
          "Mantarlı, Kaşarlı, Kavurmalı Tost",
          "Kaşarlı Tost",
          "Sucuklu Kaşarlı Tost"
        ],
        "Fiyatlar": [
          "160",
          "100",
          "100",
          "110",
          "110",
          "120",
          "180",
          "100",
          "100"
        ]
      },
      "İlkay Paşa'dan Sandviçler": {
        "Ürünler": [
          "Üç Peynirli Sandviç",
          "Karışık Sandviç",
          "Sosisli Sandviç",
          "Kavurmalı Sandviç",
          "Kavurmalı Kaşarlı Sandviç",
          "Tavuk Snitzel Sandviç",
          "Cheddar Sosisli Sandviç"
        ],
        "Fiyatlar": [
          "150",
          "150",
          "130",
          "190",
          "210",
          "125",
          "140"
        ]
      },
      "Bazlama Tostlar": {
        "Ürünler": [
          "Kavurmalı, Kaşarlı Bazlama",
          "Kavurmalı Bazlama",
          "Special Bazlama",
          "Kaşarlı Bazlama",
          "Sucuklu, Kaşarlı Bazlama",
          "Nutella Bazlama",
          "Muzlu Nutella Bazlama",
          "Tavuk Şinitzelli, Kaşarlı Bazlama",
          "Tavuk Şinitzelli Bazlama",
          "Kavurmalı, Kaşarlı, Mantarlı Bazlama",
          "Karışık Bazlama",
          "Kaşarlı, Chedarlı Bazlama",
          "Üç Peynirli Bazlama",
          "Kaşarlı, Mantarlı Bazlama",
          "Soslu Tavuk Döner Bazlama"
        ],
        "Fiyatlar": [
          "210",
          "200",
          "250",
          "130",
          "130",
          "160",
          "180",
          "170",
          "150",
          "230",
          "160",
          "160",
          "170",
          "150",
          "170"
        ]
      },
      "Ayvalık Tostları": {
        "Ürünler": [
          "Kaşarlı Ayvalık Tost",
          "Kavurmalı Kaşarlı Ayvalık Tostu",
          "Süper Karışık Ayvalık Tostu",
          "Karışık Ayvalık"
        ],
        "Fiyatlar": [
          "120",
          "170",
          "150",
          "140"
        ]
      },
      "Atıştırmalıklar": {
        "Ürünler": [
          "Sosisli Patates Kızartması",
          "Paçanga Böreği (4 Adet)",
          "Patates Kızartması",
          "Sıcak Sepeti",
          "Mozzarella Sticks (8 Adet)",
          "12'li Nugget Sepeti + Patates",
          "ATOLYE KOVA",
          "ATOLYE LUX KOVA",
          "Keyfin Kahyası Kovası 3-4 Kişilik + Patates",
          "Atolye Çıtır Kova 3-4 Kişilik + Patates",
          "Atolye Çıtır Piliç Halkaları 3-4 Kişilik + Patates",
          "12'li Soğan Halkası"
        ],
        "Fiyatlar": [
          "180",
          "200",
          "120",
          "150",
          "150",
          "180",
          "230",
          "250",
          "320",
          "320",
          "320",
          "150"
        ]
      },
      "Vitamin Bar": {
        "Ürünler": [
          "Taze Sıkım Portakal Suyu",
          "Taze Sıkım Limon, Portakal Suyu"
        ],
        "Fiyatlar": [
          "80",
          "85"
        ]
      },
      "İçecekler": {
        "Ürünler": [
          "Coca-Cola Zero",
          "Coca-Cola",
          "Fanta",
          "Sprite",
          "Fuse Tea Karpuz",
          "Niğde Gazozu",
          "Ayran",
          "Soda",
          "Limonlu Soda",
          "Şişe Su",
          "Red Bull",
          "Soda (Karpuz, Çilek)",
          "Churchill",
          "Fuse Tea Şeftali",
          "Fuse Tea Limon",
          "Fuse Tea Mango ve Ananas",
          "Schweppes Mandarin",
          "Buzlu Limonlu Bardak",
          "Karadut ve Frenk Üzümlü Soda",
          "Fuse Tea Kavun Çilek",
          "Elmalı Soda",
          "Şalgam"
        ],
        "Fiyatlar": [
          "50",
          "50",
          "50",
          "50",
          "50",
          "50",
          "30",
          "30",
          "40",
          "20",
          "85",
          "40",
          "50",
          "50",
          "50",
          "50",
          "60",
          "20",
          "40",
          "50",
          "40",
          "30"
        ]
      },
      "Refreshing Drinks": {
        "Ürünler": [
          "Çilekli Milk Shake",
          "Cool Lime",
          "Cool Berry",
          "Vanilyalı Milk Shake",
          "Mangolu Frozen",
          "Oreolu Milk Shake",
          "Kırmızı Orman Meyveli Milk Shake",
          "Karpuzlu Frozen",
          "Çilekli Frozen",
          "Kavunlu Frozen"
        ],
        "Fiyatlar": [
          "100",
          "100",
          "100",
          "100",
          "100",
          "100",
          "100",
          "100",
          "100",
          "100"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "11:00-02:45",
    "tel": "+90-507-503-8083",
    "payment": {
      "Kartlar": [
        "sodexo",
        "pluxee",
        "multinet"
      ]
    },
    "description": "Makarna çeşitleri dışında tüm ürünlerimizin yanında patates kızartması ikram edilmektedir."
  },
  {
    "id": 2,
    "name": "Cafe Rien",
    "menu": {
      "Kahvaltılıklar (11:00'a kadar)": {
        "Ürünler": [
          "Kahvaltı Tabağı",
          "Kaşarlı Tost",
          "Üç Peynirli Tost",
          "Mozarellalı Omlet",
          "Mantarlı Omlet",
          "Salam ve Jambonlu Omlet",
          "Taze Sıkma Portakal Suyu"
        ],
        "Fiyatlar": [
          "160",
          "130",
          "150",
          "130",
          "135",
          "160",
          "90"
        ],
        "Bilgi": {
          "Kahvaltı Tabağı": "Domates, Salatalık, Bal, Reçel, Tereyağı, Beyaz Peynir, Siyah Zeytin, Yeşil Zeytin ve Haşlanmış Yumurta, Sınırsız Çay ile",
          "Kaşarlı Tost": "Kaşar Peynirli Tost - Domates, Salatalık ve Zeytin ile",
          "Üç Peynirli Tost": "Cheddar Peyniri, Kaşar Peyniri ve Beyaz Peynirli Tost - Domates, Salatalık ve Zeytin ile",
          "Mozarellalı Omlet": "Mozarellalı Omlet, Domates ve Salatalık ile",
          "Mantarlı Omlet": "Mozarella ve Mantarlı Omlet, Domates ve Salatalık ile",
          "Salam ve Jambonlu Omlet": "Dana Jambon ve Salamlı Omlet, Domates ve Salatalık ile"
        }
      },
      "Aperatifler": {
        "Ürünler": [
          "Patates Kızartması",
          "Soğan Halkası",
          "Nugget",
          "Kızartma Tabağı"
        ],
        "Fiyatlar": [
          "75",
          "60",
          "120",
          "170"
        ],
        "Bilgi": {
          "Soğan Halkası": "8 adet Soğan Halkası",
          "Nugget": "Çıtır tavuk parçaları, Patates kızartması ile",
          "Kızartma Tabağı": "Soğan Halkası, Çıtır tavuk parçaları, Patates Kızartması ile"
        }
      },
      "Noodle ve Makarnalar": {
        "Ürünler": [
          "Penne Alfredo",
          "Penne Napolitan",
          "Penne Bolonez",
          "Köri Mantarlı Penne",
          "Ravigot Penne",
          "Carbonara",
          "Etli Noodle",
          "Tavuklu Noodle",
          "Sebzeli Noodle"
        ],
        "Fiyatlar": [
          "200",
          "130",
          "230",
          "200",
          "210",
          "250",
          "320",
          "220",
          "180"
        ],
        "Bilgi": {
          "Penne Alfredo": "Krema, Mantar ve Soya Sosu ile sotelenmiş Tavuk dilimleri ile soslanmış Penne Makarna",
          "Penne Napolitan": "Napolitan Sos ile soslanmış Penne Makarna",
          "Penne Bolonez": "Dana Kıyma, Soğan, Sarımsak, Kapya biber ve Çarliston biber ile soslanmış Penne Makarna",
          "Köri Mantarlı Penne": "Köri, Mantar ve Krema ile sotelenmiş Jülyen Tavuk Dilimleri ile soslanmış Penne Makarna",
          "Ravigot Penne": "Jalapeno Biber, Domates, Krema ve Muskat cevizi ile sotelenmiş Tavuk Dilimleri ile soslanmış Penne Makarna",
          "Carbonara": "Krema, Dana Jambon, Salam ve Soya sosu ile soslanmış Penne Makarna",
          "Etli Noodle": "Kabak, Havuç, Beyaz Lahana, Susam Yağı ve Soya sosu ile sotelenmiş Julyen Dana Biftek parçaları ile tatlandırılmış Çin Eriştesi - Yeşil Soğan ve Kavrulmuş Susam ile",
          "Tavuklu Noodle": "Kabak, Havuç, Beyaz Lahana, Susam Yağı ve Soya sosu ile sotelenmiş Julyen Tavuk parçaları ile tatlandırılmış Çin Eriştesi - Yeşil Soğan ve Kavrulmuş Susam ile",
          "Sebzeli Noodle": "Susam yağı ve Soya sosu ile sotelenmiş Kabak, Havuç, Beyaz Lahana ile tatlandırılmış Çin Eriştesi - Yeşil Soğan ve Kavrulmuş Susam ile"
        }
      },
      "Burgerler": {
        "Ürünler": [
          "Cheese Burger",
          "BBQ Mushroom Burger",
          "Cheese-Onion Burger",
          "Hamburger",
          "Crispy Chicken Burger",
          "Black Truffle"
        ],
        "Fiyatlar": [
          "240",
          "250",
          "245",
          "230",
          "210",
          "260"
        ],
        "Bilgi": {
          "Cheese Burger": "El yapımı Burger Ekmeği arasında, el yapımı Köfte üzerine Cheddar Peyniri, Domates, Marul, Kornişon Turşu - Patates Kızartması ile",
          "BBQ Mushroom Burger": "El yapımı Burger Ekmeği arasında, el yapımı Köfte üzerine BBQ sos ile sotelenmiş Soğan ve Mantar, Domates, Marul, Kornişon Turşu - Patates Kızartması ile",
          "Cheese-Onion Burger": "El yapımı Burger Ekmeği arasında, el yapımı Köfte üzerine Karamelize Soğan ve Cheddar Peyniri, Domates, Kornişon Turşu, Marul- Patates Kızartması ile",
          "Hamburger": "El yapımı Burger Ekmeği arasında, el yapımı Köfte, Domates, Kornişon Turşu, Marul - Patates Kızartması ile",
          "Crispy Chicken Burger": "El yapımı Burger Ekmeği arasında Çıtır tavuk, Mayonez, Domates, Kornişon Turşu, Akdeniz Yeşillikleri - Patates Kızartması ile",
          "Black Truffle": "Siyah Trüf mantarlı mayonez ile tatlandırılmış Saman Patates üzerinde, el yapımı Köfte, Cheddar Peyniri, Karamelize Soğan ve Kornişon Turşu - Patates Kızartması ile"
        }
      },
      "Quesadilla ve Wrapler": {
        "Ürünler": [
          "Beef Wrap",
          "Sebzeli Wrap",
          "Çıtır Tavuklu Wrap",
          "Tavuklu Quesadilla",
          "Beef Quesadilla"
        ],
        "Fiyatlar": [
          "320",
          "180",
          "230",
          "230",
          "330"
        ],
        "Bilgi": {
          "Beef Wrap": "Lavaş ekmeğine sarılmış, Mozarella Peyniri ile tatlandırılmış, Kapya ve Soğan ile sotelenmiş Dana Biftek dilimleri - Patates Kızartması ile",
          "Sebzeli Wrap": "Lavaş ekmeğine sarılmış, Mozarella Peyniri ile tatlandırılmış, Soya sosu ile sotelenmiş Kabak, Havuç, Soğan, Kapya biber ve Mantar- Patates Kızartması ile",
          "Çıtır Tavuklu Wrap": "Lavaş Ekmeğine Sarılmış, Taze Baharatlar ile marine edilmiş Çıtır Tavuk Dilimleri, Mozarella Peyniri, Marul, Turşu - Patates Kızartması ile",
          "Tavuklu Quesadilla": "Kızartılmış Lavaş ekmeği arasında, Cheddar Peyniri ve Mozarella ile tatlandırılmış Kapya Biber, Çarliston Biber, Soğan, Meksika Fasulyesi, Jalapeno biberli Kızarmış Tavuk Parçaları - Soslu Yeşil salata ve Patates kızartması ile",
          "Beef Quesadilla": "Kızartılmış Lavaş ekmeği arasında, Cheddar Peyniri ve Mozarella ile tatlandırılmış Kapya Biber, Çarliston Biber, Soğan, Meksika Fasulyesi, Jalapeno biber ile sotelenmiş Dana Biftek Parçaları - Soslu Yeşil salata ve Patates kızartması ile"
        }
      },
      "Salatalar": {
        "Ürünler": [
          "Ton Balıklı Salata",
          "Kızarmış Tavuklu Salata",
          "Izgara Tavuklu Salata",
          "Kızarmış Peynirli Salata",
          "Cajun Soslu Tavuklu Salata"
        ],
        "Fiyatlar": [
          "255",
          "220",
          "220",
          "200",
          "210"
        ],
        "Bilgi": {
          "Ton Balıklı Salata": "Marul, Kiraz Domates, Salatalık, Havuç, Mısır ve Mor Lahana Turşusu üzerinde 160 gr Ton Balığı",
          "Kızarmış Tavuklu Salata": "Marul, Kiraz Domates, Salatalık, Havuç, Mısır ve Mor Lahana Turşusu üzerinde Çıtır Tavuk Parçaları",
          "Izgara Tavuklu Salata": "Marul, Kiraz Domates, Salatalık, Havuç, Mısır ve Mor Lahana Turşusu üzerinde Izgara Tavuk Parçaları",
          "Kızarmış Peynirli Salata": "Marul, Kuru Üzüm, Kuru Domates, Kiraz Domates, Salatalık, Havuç, Mısır ve Mor Lahana Turşusu üzerinde Kızarmış Köy Peyniri",
          "Cajun Soslu Tavuklu Salata": "Marul, Kiraz Domates, Salatalık, Havuç, Mısır ve Mor Lahana Turşusu üzerinde Cajun Baharatı ve Krema ile tatlandırılmış Tavuk parçaları"
        }
      },
      "Tava Yemekleri": {
        "Ürünler": [
          "Köri Mantarlı Tavuk",
          "Schnitzel",
          "Piliç Strogonoff",
          "Piliç Fajita",
          "Et Fajita",
          "Susamlı Teriyaki Tavuk",
          "Colorado Chicken"
        ],
        "Fiyatlar": [
          "230",
          "240",
          "240",
          "235",
          "310",
          "230",
          "230"
        ],
        "Bilgi": {
          "Köri Mantarlı Tavuk": "Köri, Mantar ve Krema ile sotelenmiş Tavuk Dilimleri - Patates ve Blanşe Sebze ile",
          "Schnitzel": "Baharatlar ve özel harcı ile kaplı kızarmış Tavuk göğsü – Patates ve Blanşe sebze ile",
          "Piliç Strogonoff": "Mantar, Soğan, Kornişon Turşu, Hardal ve Krema ile sotelenmiş Tavuk Parçaları - Patates ve Blanşe Sebze ile",
          "Piliç Fajita": "Sarımsak ve Soya sosu ile sotelenmiş Kapya ve Çarliston biber yatağında, baharat karışımı ile sotelenmiş Tavuk parçaları - Tortilla ekmeği, Salsa Sos ve Sweet Chili sos ile",
          "Et Fajita": "Sarımsak ve Soya sosu ile sotelenmiş Kapya ve Çarliston biber yatağında, baharat karışımı ile sotelenmiş Dana Antrikot parçaları - Tortilla ekmeği, Salsa Sos ve Sweet Chili sos ile",
          "Susamlı Teriyaki Tavuk": "Kavrulmuş Susam ve Teriyaki glaze ile soslanmış Julyen Tavuk parçaları - Kremalı Ispanak ve Basmati pilavı ile",
          "Colorado Chicken": "BBQ Sos ile Sotelenmiş Kapya Biber, Çarliston Biber ve Julyen Tavuk Parçaları - Basmati pilavı ve Blanşe sebze ile"
        }
      },
      "Izgaralar": {
        "Ürünler": [
          "Izgara Köfte",
          "Izgara Tavuk",
          "Hollandez Soslu Izgara Tavuk"
        ],
        "Fiyatlar": [
          "230",
          "210",
          "220"
        ],
        "Bilgi": {
          "Izgara Köfte": "Izgarada Pişmiş 200gr Köfte, Izgara Domates ve Izgara biber - Patates ve Blanşe Sebze ile",
          "Izgara Tavuk": "Baharatlar ile marine edilmiş ve Izgarada Pişmiş Tavuk Kalçası - Patates ve Blanşe Sebze ile",
          "Hollandez Soslu Izgara Tavuk": "Kızarmış Havuç ve Patates yatağında, Marine edilmiş Tavuk Göğsü üzerine Hollandez sos - Patates püresi ve Mevsim sebzeleri ile"
        }
      },
      "Gratenler": {
        "Ürünler": [
          "Lazanya Bolonez"
        ],
        "Fiyatlar": [
          "260"
        ],
        "Bilgi": {
          "Lazanya Bolonez": "Lazanya Yaprakları arasında, Dana Kıyma, Soğan, Sarımsak, Kapya biber ve Çarliston biber ile hazırlanan Bolonoz Sos ve Gratine edilmiş Beşamel sos - Küçük boy salata ile"
        }
      },
      "Sıcak İçecekler ve Kahveler": {
        "Ürünler": [
          "Çay",
          "Bitki Çayları",
          "Türk Kahvesi",
          "Espresso",
          "Double Espresso",
          "Filtre Kahve",
          "Cafe Americano",
          "Cafe Latte",
          "Flat White",
          "Chocolate Mocha",
          "White Chocolate Mocha",
          "Cappucino",
          "Iced Cafe Americano",
          "Iced Cafe Latte",
          "Iced Chocolate Mocha",
          "Iced White Chocolate Mocha",
          "Iced Cappucino",
          "İlave Espresso Shot",
          "İlave Şurup",
          "Ruby Chocolate Mocha",
          null,
          "Chocolate Mint Mocha",
          "Toffee Nut Caramel Latte"
        ],
        "Fiyatlar": [
          "20",
          "80",
          "40",
          "50",
          "80",
          "60",
          "65",
          "80",
          "85",
          "90",
          "90",
          "85",
          "70",
          "85",
          "90",
          "90",
          "90",
          "20",
          "15",
          "110",
          null,
          "110",
          "110"
        ],
        "Bilgi": {
          "Bitki Çayları": "Çeşitleri için lütfen bilgi alınız"
        }
      },
      "Soğuk İçecekler": {
        "Ürünler": [
          "Kutu İçecekler",
          "Ev Yapımı Limonata",
          "Soda",
          "Su",
          "Ayran",
          "Churchill"
        ],
        "Fiyatlar": [
          "50",
          "60",
          "35",
          "15",
          "35",
          "50"
        ]
      },
      "Tatlılar": {
        "Ürünler": [
          "Cheesecake",
          "Dilim Pasta",
          "Tiramisu",
          "Magnolia"
        ],
        "Fiyatlar": [
          "130",
          "130",
          "130",
          "100"
        ],
        "Bilgi": {
          "Cheesecake": "Çeşitleri için lütfen bilgi alınız"
        }
      }
    },
    "status": "Açık",
    "open_hours": "08:00-23:00",
    "tel": "+90-850-308-0376",
    "payment": {},
    "description": "Salon Çalışma Saatleri: 08:00-22:30 |\r\nPaket Servis Saatleri: 11:00-23:00"
  },
  {
    "id": 3,
    "name": "Crispy",
    "menu": {
      "Çıtır Tavuk": {
        "Kategoriler": [
          "1 Porsiyon",
          "1.5 Porsiyon"
        ],
        "Çıtır Tavuk": [
          "200",
          "290"
        ]
      },
      "Burgers": {
        "Ürünler": [
          "Chicken Burger + Patates",
          "Chicken Cheese Burger + Patates",
          "Double Chicken Burger + Patates",
          "Double Chicken Cheese Burger + Patates",
          "Hamburger + Patates",
          "Cheeseburger + Patates",
          "Double Hamburger + Patates"
        ],
        "Fiyatlar": [
          "200",
          "220",
          "290",
          "310",
          "290",
          "310",
          "400"
        ]
      },
      "Aperatifler": {
        "Ürünler": [
          "Soğan Halkası (4'lü)",
          "Patates Kızartması (Double)"
        ],
        "Fiyatlar": [
          "50",
          "70"
        ]
      },
      "Soslar": {
        "Ürünler": [
          "Ranch Sos",
          "Acı Sos",
          "Barbekü Sos"
        ],
        "Fiyatlar": [
          "5",
          "5",
          "5"
        ]
      },
      "İçecek": {
        "Ürünler": [
          "Kola (1 Litre)",
          "Su",
          "Kola",
          "Sprite",
          "Ice Tea",
          "Fanta",
          "Ayran",
          "Soda"
        ],
        "Fiyatlar": [
          "70",
          "15",
          "50",
          "50",
          "50",
          "50",
          "25",
          "10"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "12:00-23:00",
    "tel": "+90-507-211-2068",
    "payment": {},
    "description": "Menüye İlave Patates 30₺"
  },
  {
    "id": 4,
    "name": "Fabrika Kitchen",
    "menu": {
      "Aperatifler": {
        "Ürünler": [
          "Patates Kızartması",
          "Elma Dilim Patates",
          "Çıtır Tavuk",
          "Soğan Halkası",
          "Mozzarella Sticks",
          "Fabrika Çıtır Sepet",
          "Fabrika Mega Çıtır Sepet",
          "Cheddarlı Patates"
        ],
        "Fiyatlar": [
          "80",
          "90",
          "180",
          "100",
          "150",
          "150",
          "200",
          "110"
        ],
        "Bilgi": {
          "Çıtır Tavuk": "300 gr çıtır tavuk patates ile birlikte",
          "Soğan Halkası": "8 adet soğan halkası patates ile birlikte",
          "Mozzarella Sticks": "8 adet sticks patates ile birlikte",
          "Fabrika Çıtır Sepet": "3 çıtır tavuk, 3 soğan halkası, 3 mozarella sticks, 3 sosis, patates ile birlikte",
          "Fabrika Mega Çıtır Sepet": "5 çıtır tavuk, 5 soğan halkası, 5 mozarella sticks, 5 sosis, patates ile birlikte"
        }
      },
      "Soğuk Salatalar": {
        "Ürünler": [
          "Ton Balıklı Salata",
          "Akdeniz Salata",
          "Greek Salata",
          "Mevsim Salata"
        ],
        "Fiyatlar": [
          "190",
          "130",
          "130",
          "110"
        ],
        "Bilgi": {
          "Ton Balıklı Salata": "Akdeniz yeşillikleri, ton balığı, domates, kırmızı soğan, zeytin dilimleri, mısır",
          "Akdeniz Salata": "Akdeniz yeşillikleri, zeytin dilimleri, mozzarella, beyaz peynir, ceviz",
          "Greek Salata": "Akdeniz yeşillikleri, beyaz peynir, kırmızı soğan, salatalık",
          "Mevsim Salata": "Mevsim yeşillikleri, kırmızı lahana, mısır, domates, salata"
        }
      },
      "Sıcak Salatalar": {
        "Ürünler": [
          "Tavuklu Sezar Salata",
          "Izgara Tavuk Salata",
          "Tavuk Salata",
          "Çıtır Tavuk Salata"
        ],
        "Fiyatlar": [
          "170",
          "150",
          "140",
          "150"
        ],
        "Bilgi": {
          "Tavuklu Sezar Salata": "Akdeniz yeşillikleri, roka, kruton, tavuk göğsü, sezar sos ile",
          "Izgara Tavuk Salata": "Mevsim yeşillikleri, ızgara tavuk, domates, salatalık, mısır",
          "Tavuk Salata": "Mevsim yeşillikleri, tavuk göğsü, roka, domates, turşu",
          "Çıtır Tavuk Salata": "Mevsim yeşillikleri, çıtır tavuk parçaları, kırmızı lahana"
        }
      },
      "Wrapler": {
        "Ürünler": [
          "Tavuk Wrap",
          "Et Wrap",
          "Şinitzel Wrap",
          "Köfte Wrap",
          "Burrito"
        ],
        "Fiyatlar": [
          "150",
          "210",
          "160",
          "210",
          "160"
        ],
        "Bilgi": {
          "Tavuk Wrap": "Krema, tavuk göğsü, renkli biberler, mozzarella, yanında patates kızartması",
          "Et Wrap": "Krema, et bonfile, renkli biberler, cheddar, yanında patates ile birlikte",
          "Şinitzel Wrap": "Krema, çıtır tavuk, renkli biberler, mozzarella, yanında patates kızartması",
          "Köfte Wrap": "Krema, 140 gr köfte, renkli biber, cheddar, yanında patates kızartması",
          "Burrito": "Krema, tavuk göğsü, renkli biber, mantar, mozzarella, mısır, yanında patates"
        }
      },
      "Quesadilla": {
        "Ürünler": [
          "Tavuk Quesadilla",
          "Et Quesadilla"
        ],
        "Fiyatlar": [
          "180",
          "240"
        ],
        "Bilgi": {
          "Tavuk Quesadilla": "Krema, tavuk göğsü, renkli biberler, mısır, mantar, cheddar, yanında patates",
          "Et Quesadilla": "Krema, bonfile et, renkli biberler, mantar, mısır, cheddar, yanında patates"
        }
      },
      "Izgaralar": {
        "Ürünler": [
          "Izgara Köfte",
          "Izgara Tavuk"
        ],
        "Fiyatlar": [
          "240",
          "190"
        ],
        "Bilgi": {
          "Izgara Köfte": "200 gr ızgara köfte, közlenmiş biber, domates, salata, patates ve tortilla ile birlikte",
          "Izgara Tavuk": "180 gr marine edilmiş tavuk, közlenmiş domates, biber, salata, patates ve tortilla ile birlikte"
        }
      },
      "Tavalar": {
        "Ürünler": [
          "Köri Soslu Tavuk",
          "Teriyaki Soslu Tavuk",
          "Mexican Soslu Tavuk",
          "BBQ Soslu Tavuk",
          "Kremalı Mantarlı Tavuk"
        ],
        "Fiyatlar": [
          "210",
          "210",
          "210",
          "210",
          "200"
        ]
      },
      "Makarnalar": {
        "Ürünler": [
          "Penne Bolognese",
          "Penne Arabiata",
          "Alfredo Penne",
          "Curry Penne",
          "Pesto Penne ",
          "Sebzeli Penne",
          "Penne Emiliano",
          "Spagetti Bolognese",
          "Spagetti Napoliten",
          "Penne Napoliten",
          "Tortellini",
          "Ton Balıklı Penne"
        ],
        "Fiyatlar": [
          "200",
          "140",
          "150",
          "160",
          "160",
          "140",
          "220",
          "210",
          "130",
          "120",
          "220",
          "170"
        ]
      },
      "Burgerler": {
        "Ürünler": [
          "Chicken Burger",
          "Hamburger",
          "Cheese Burger",
          "Füme Kaburga Burger",
          "Fabrika Burger",
          "BBQ Burger",
          "Mushroom Burger",
          "Mexican Burger",
          "Dublex Burger"
        ],
        "Fiyatlar": [
          "180",
          "230",
          "240",
          "280",
          "280",
          "250",
          "260",
          "250",
          "380"
        ]
      },
      "Pizzalar": {
        "Ürünler": [
          "Margarita",
          "Funghi",
          "Pepperoni",
          "Fabrika Mix",
          "Füme Kaburga Pizza",
          "Vejeteryan Pizza",
          "Quattro Formaggi",
          "Polo Pizza",
          "Mexicano Pizza",
          "Ton Balıklı Pizza",
          "Pizza Turco",
          "Şarküteri Pizza",
          "Kavurmalı Pizza",
          "Etli Pizza"
        ],
        "Fiyatlar": [
          "160",
          "180",
          "220",
          "230",
          "280",
          "180",
          "250",
          "240",
          "240",
          "240",
          "300",
          "220",
          "275",
          "290"
        ],
        "Bilgi": {
          "Margarita": "Pizza sos, mozarella, domates, yanında patates",
          "Funghi": "Pizza sos, mozarella, mantar,  yanında patates",
          "Pepperoni": "Pizza sos, mozarella, sucuk, jalapeno,  yanında patates",
          "Fabrika Mix": "Pizza sos, mozarella, sucuk, mantar, sosis, renkli biber, zeytin dilimleri, mısır, yanında patates",
          "Füme Kaburga Pizza": "Pizza sos, mozarella, kırmızı soğan, füme kaburga, roka, parmesan, yanında patates",
          "Vejeteryan Pizza": "Pizza sos, mozarella, mantar, renkli biberler, mısır, zeytin dilimleri",
          "Quattro Formaggi": "Pizza sos, mozarella, rokfor, cheddar, parmesan, yanında patates",
          "Polo Pizza": "Pizza sos, mozarella, tavuk, mantar, BBQ sos",
          "Mexicano Pizza": "Pizza sos, acı sos, mozarella, renkli biber, mısır, tavuk, mexican fasulye, jalapeno, yanında patates",
          "Ton Balıklı Pizza": "Pizza sos, mozarella, mısır, ton balığı, kırmızı soğan, yanında patates",
          "Pizza Turco": "Pizza sos, mozarella, pastırma, kırmızı soğan, yeşil biber,  yanında patates",
          "Şarküteri Pizza": "Pizza sos, mozarella, sucuk, salam, sosis, yanında patates",
          "Kavurmalı Pizza": "Pizza sos, mozarella, kavurma, renkli biber, mantar, mantar",
          "Etli Pizza": "Pizza sos, mozarella, dana bonfile, kırmızı soğan"
        }
      },
      "İçecekler": {
        "Ürünler": [
          "Su",
          "Soda",
          "Büyük Ayran",
          "Coca Cola",
          "Coca Cola Zero",
          "Fanta",
          "Sprite",
          "Ice Tea (Şeftali, Mango, Limon)",
          "Redbull",
          "Litrelik Kola (Sadece Paket)"
        ],
        "Fiyatlar": [
          "20",
          "20",
          "40",
          "50",
          "50",
          "50",
          "50",
          "50",
          "70",
          "80"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "10:00-02:00",
    "tel": "+90-546-150-0001",
    "payment": {
      "Kartlar": [
        "sodexo",
        "pluxee",
        "multinet"
      ]
    },
    "description": ""
  },
  {
    "id": 5,
    "name": "Köfteci Salih Usta",
    "menu": {
      "Ekmek Arası": {
        "Kategoriler": [
          "Yarım",
          "Üç Çeyrek",
          "Tam"
        ],
        "Köfte Ekmek": [
          "90",
          "120",
          "140"
        ],
        "Tavuk Ekmek": [
          "90",
          "120",
          "140"
        ],
        "Ciğer Ekmek": [
          "100",
          "130",
          "150"
        ],
        "Sucuk Ekmek": [
          "90",
          "120",
          "140"
        ],
        "Sucuk Kaşar Yumurta": [
          "100",
          "130",
          "150"
        ],
        "Sucuk Tavuk Köfte Ekmek": [
          "100",
          "130",
          "150"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "08:00-23:00",
    "tel": "+90-232-765-8135",
    "payment": {},
    "description": "Pazar günleri kapalıdır."
  },
  {
    "id": 6,
    "name": "Marina Döner",
    "menu": {
      "İskenderun Döner": {
        "Ürünler": [
          "Tek",
          "Duble"
        ],
        "Fiyatlar": [
          "140",
          "160"
        ]
      },
      "Pilav Üstü Döner": {
        "Ürünler": [
          "Pilav Üstü"
        ],
        "Fiyatlar": [
          "170"
        ]
      },
      "Pilav": {
        "Ürünler": [
          "Pilav + Tiftik Tavuk + Salata + Ayran",
          "Pilav + Tavuk Sote + Salata + Ayran",
          "Pilav + Köri Soslu Tavuk + Salata + Ayran",
          "Pilav + Meksika Soslu Tavuk + Salata + Ayran",
          "Pilav + Kuru Fasulye + Salata + Ayran",
          "Nohutlu Pilav + Ayran"
        ],
        "Fiyatlar": [
          "120",
          "130",
          "140",
          "140",
          "120",
          "100"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "11:00-01:00",
    "tel": "+90-553-905-9414",
    "payment": {},
    "description": ""
  },
  {
    "id": 7,
    "name": "Maviş Ev Yemekleri",
    "menu": {
      "Yemek & Çorbalar": {
        "Ürünler": [
          "Mercimek Çorbası",
          "Kelle Paça Çorbası",
          "Ev Mantısı",
          "Et Döner (Yarım Ekmek)",
          "Et Döner (3 Çeyrek)",
          "Et Döner (Pilav Üstü)",
          "Tavuk Döner (Yarım Ekmek)",
          "Tavuk Döner (3 Çeyrek)",
          "Tavuk Döner (Pilav Üstü)"
        ],
        "Fiyatlar": [
          "120",
          "150",
          "150",
          "150",
          "180",
          "250",
          "100",
          "140",
          "180"
        ]
      },
      "Kahvaltı": {
        "Ürünler": [
          "Kahvaltı Tabağı",
          "Serpme Kahvaltı",
          "Sucuklu Yumurta",
          "Menemen",
          "Sahanda Yumurta",
          "Omlet",
          "Karışık Tost",
          "Günün Tatlısı"
        ],
        "Fiyatlar": [
          "250",
          "350",
          "130",
          "130",
          "80",
          "100",
          "130",
          "40"
        ]
      },
      "İçecekler": {
        "Ürünler": [
          "Su (0.5 ml)",
          "Küçük Ayran",
          "Büyük Ayran",
          "Soda (Sade)",
          "Soda (Meyveli)",
          "Kola (Kutu)",
          "Kola (1 lt)",
          "Kola (2.5 lt)",
          "Fanta (Kutu)",
          "Sprite (Kutu)",
          "Fuse Tea (Kutu)",
          "Çay (Yemek Hariç)",
          "Niğde Gazozu",
          "Kahve",
          "Nescafe",
          "Şalgam Suyu"
        ],
        "Fiyatlar": [
          "10",
          "20",
          "25",
          "25",
          "25",
          "35",
          "60",
          "80",
          "40",
          "40",
          "40",
          "10",
          "30",
          "30",
          "30",
          "30"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "08:00-20:00",
    "tel": "+90-542-765-7006",
    "payment": {},
    "description": "Pazar günleri kapalıdır. Öğrenci indirimi (işyerinde 150 TL, paket 170 TL) vardır."
  },
  {
    "id": 8,
    "name": "Mellon Coffee",
    "menu": {
      "Sıcak Kahveler": {
        "Ürünler": [
          "Single Espresso",
          "Double Espresso",
          "Filtre Kahve",
          "Americano",
          "Cappucino",
          "Coffee Latte",
          "Lungo",
          "Cortado",
          "Flat White",
          "Macchiato",
          "Caramel Macchiato",
          "Mocha",
          "White Mocha",
          "Caramel Mocha",
          "Pumpkin Spice Latte",
          "Almond Latte",
          "Chai Tea Latte",
          "Salted Caramel Latte",
          "Hot Chocolatte",
          "Hot White Chocolatte"
        ],
        "Fiyatlar": [
          "75",
          "85",
          "100",
          "100",
          "105",
          "110",
          "90",
          "105",
          "110",
          "120",
          "135",
          "130",
          "130",
          "130",
          "135",
          "135",
          "145",
          "135",
          "120",
          "120"
        ]
      },
      "Soğuk Kahveler": {
        "Ürünler": [
          "İce Americano",
          "İce Coffee Latte",
          "İce Flat White",
          "İce Caramel Macchiato",
          "İce Mocha",
          "İce White Mocha",
          "İce Caramel Mocha",
          "İce Pumpkin Spice Latte",
          "İce Almond Latte",
          "İce Chai Tea Latte",
          "İce Salted Caramel Latte",
          "Cold Brew",
          "Cold Brew Latte",
          "İce Frappe"
        ],
        "Fiyatlar": [
          "115",
          "120",
          "120",
          "140",
          "140",
          "140",
          "150",
          "150",
          "140",
          "150",
          "140",
          "120",
          "130",
          "135"
        ]
      },
      "3RD Wave": {
        "Ürünler": [
          "Chemex",
          "V60"
        ],
        "Fiyatlar": [
          "140",
          "135"
        ]
      },
      "Frozen": {
        "Ürünler": [
          "Mango",
          "Çilek",
          "Orman Meyveli",
          "Karadut",
          "Böğürtlen"
        ],
        "Fiyatlar": [
          "145",
          "145",
          "145",
          "145",
          "145"
        ]
      },
      "İçecekler": {
        "Ürünler": [
          "Su",
          "Çay",
          "Bitki Çayları",
          "Sahlep",
          "Türk Kahvesi",
          "Soda",
          "Meyveli Soda",
          "Churchill",
          "Gazoz",
          "Cola, Fanta, Sprite",
          "Meyve Suyu",
          "Redbull",
          "Limonata"
        ],
        "Fiyatlar": [
          "20",
          "25",
          "60",
          "80",
          "60",
          "45",
          "50",
          "75",
          "55",
          "70",
          "60",
          "100",
          "85"
        ]
      },
      "Refresha": {
        "Ürünler": [
          "Cool Lime",
          "Berry Hibiscus",
          "Watermelon Lemonade"
        ],
        "Fiyatlar": [
          "125",
          "125",
          "125"
        ]
      },
      "Pasta": {
        "Ürünler": [
          "Limonlu Cheesecake",
          "Frambuazlı Cheesecake",
          "Lotuslu Cheesecake",
          "Browni Üç Çikolatalı",
          "San Sebastian",
          "Spekülas Dome",
          "Pasta Latte",
          "Frambuazlı Çikolatalı",
          "Yaban Mersinli Krepli",
          "Çikolatalı Cheesecake"
        ],
        "Fiyatlar": [
          "120",
          "120",
          "120",
          "200",
          "120",
          "130",
          "120",
          "140",
          "140",
          "150"
        ]
      },
      "İlaveler": {
        "Ürünler": [
          "Süt",
          "Laktozsuz",
          "Badem Sütü",
          "Extra Shot",
          "Extra Şurup"
        ],
        "Fiyatlar": [
          "20",
          "15",
          "25",
          "25",
          "25"
        ]
      }
    },
    "status": "Yok",
    "open_hours": "10:00-01:00",
    "tel": "+90-532-416-8924",
    "payment": {},
    "description": ""
  },
  {
    "id": 9,
    "name": "Nazilli Pide Salonu",
    "menu": {
      "Çorba Çeşitleri": {
        "Ürünler": [
          "Mercimek",
          "Ezogelin",
          "Tavuk Suyu",
          "İşkembe",
          "Kellepaça",
          "Beyin",
          "Ayakpaça",
          "Karışık",
          "Full Karışık"
        ],
        "Fiyatlar": [
          "140",
          "140",
          "180",
          "190",
          "180",
          "180",
          "180",
          "200",
          "200"
        ]
      },
      "Pide Çeşitleri": {
        "Ürünler": [
          "Sucuklu Kaşarlı Yumurtalı",
          "Special Pide",
          "Pastırmalı Kaşarlı",
          "Tavuklu Sade",
          "Tavuklu Kaşarlı",
          "Sebzeli Pide",
          "3 Peynirli Pide",
          "Ispanaklı Peynirli",
          "Naturel (Sade Kıyma)",
          "Naturel Kaşarlı (Sade Kıyma + Kaşar)",
          "Karışık Pide",
          "Sebzeli Kaşarlı Pide",
          "Vegan Pide",
          "Kıymalı Pide",
          "Kıymalı Yumurtalı",
          "Kıymalı Kaşarlı",
          "Kuşbaşılı Sade",
          "Kuşbaşılı Kaşarlı",
          "Kuşbaşılı Yumurtalı",
          "Kavurmalı Sade",
          "Kavurmalı Kaşarlı",
          "Kaşarlı Sade",
          "Kaşarlı Yumurtalı",
          "Kaşarlı Sucuklu",
          "Kaşarlı Mantarlı"
        ],
        "Fiyatlar": [
          "240",
          "300",
          "300",
          "230",
          "280",
          "180",
          "200",
          "200",
          "250",
          "270",
          "270",
          "200",
          "180",
          "190",
          "200",
          "230",
          "250",
          "280",
          "260",
          "280",
          "300",
          "190",
          "200",
          "230",
          "200"
        ]
      },
      "Lahmacun Çeşitleri": {
        "Ürünler": [
          "Fındık Lahmacun",
          "Lahmacun",
          "Antep Lahmacun",
          "Antep Lahmacun Kaşarlı",
          "Kıymalı Kaşarlı Lahmacun",
          "Nazilli Gülü Porsiyon",
          "Kuşbaşı Kaşarlı Lahmacun"
        ],
        "Fiyatlar": [
          120,
          "160",
          "200",
          "240",
          "200",
          "250",
          "240"
        ]
      },
      "Kebap Çeşitleri": {
        "Ürünler": [
          "Adana (Acılı)",
          "Urfa",
          "Tavuk Şiş İncik",
          "Tavuk Kanat Şiş",
          "Izgara Köfte",
          "Beyti Sarma",
          "Adana İskender",
          "Urfa İskender",
          "Patlıcan Kebap",
          "Manisa Kebap",
          "Pirzola",
          "Bonfile",
          "Antrikot",
          "Et Şiş",
          "Ciğer Şiş",
          "Ciğer Tava",
          "Kuzu Şiş Çöp",
          "Kuzu Kaburga",
          "Izgara Kaşarlı Köfte",
          "Karışık Izgara",
          "Vali (2 Kişilik)",
          "Nazilli Special"
        ],
        "Fiyatlar": [
          "300",
          "300",
          "300",
          "300",
          "320",
          "320",
          "320",
          "320",
          "350",
          "320",
          "400",
          "350",
          "350",
          "360",
          "300",
          "300",
          "350",
          "370",
          "350",
          "650",
          "1300",
          "2800"
        ]
      },
      "Tava Çeşitleri": {
        "Ürünler": [
          "Sac Kavurma",
          "Kilis Tava",
          "Piliç Tava",
          "Kurban Kavurma"
        ],
        "Fiyatlar": [
          "350",
          "300",
          "300",
          "370"
        ]
      },
      "Kiremit Çeşitleri": {
        "Ürünler": [
          "Kiremitte Köfte",
          "Kiremitte Kaşarlı Köfte",
          "Kiremitte Tavuk",
          "Kiremitte Kaşarlı Tavuk",
          "Kiremitte Mantar Kaşar"
        ],
        "Fiyatlar": [
          "300",
          "320",
          "300",
          "320",
          "230"
        ]
      },
      "Pizza Çeşitleri": {
        "Ürünler": [
          "Sebzeli Pizza",
          "Karışık Pizza",
          "Sucuklu Pizza",
          "Pastırmalı Pizza",
          "Kavurmalı Pizza",
          "Ton Balıklı Pizza",
          "Nazilli Special Pizza",
          "Patates Cips Porsiyon"
        ],
        "Fiyatlar": [
          "230",
          "260",
          "320",
          "320",
          "320",
          "300",
          "350",
          "120"
        ]
      },
      "Kahvaltı": {
        "Ürünler": [
          "Kahvaltı Tabağı",
          "Serpme Kahvaltı",
          "Menemen",
          "Menemen Kaşar Sucuklu",
          "Menemen Kaşarlı",
          "Sucuklu Yumurta",
          "Yumurta Sahanda",
          "Omlet",
          "Omlet Kaşarlı",
          "Pastırmalı Yumurta",
          "Kavurmalı Yumurta",
          "Pişi",
          "Haşlanmış Yumurta"
        ],
        "Fiyatlar": [
          "350",
          "700",
          "140",
          "200",
          "160",
          "180",
          "100",
          "150",
          "200",
          "250",
          "250",
          "50",
          "20"
        ]
      },
      "Salata Çeşitleri": {
        "Ürünler": [
          "Çoban Salata",
          "Mevsim Salata",
          "Peynirli Salata",
          "Gavurdağ Salata",
          "Tavuklu Salata",
          "Ton Balıklı Salata"
        ],
        "Fiyatlar": [
          "160",
          "160",
          "180",
          "200",
          "260",
          "260"
        ]
      },
      "Tatlı Çeşitleri": {
        "Ürünler": [
          "Kara Fırın Künefe",
          "Kara Fırın Katmer",
          "Fıstıklı Kadayıf",
          "Tahinli Pide (Ballı Cevizli)",
          "Çikolatalı Pide",
          "Sütlaç",
          "Kemalpaşa",
          "İlave Kaymak",
          "İlave Dondurma"
        ],
        "Fiyatlar": [
          "150",
          "220",
          "220",
          "260",
          "160",
          "100",
          "100",
          "50",
          "75"
        ]
      },
      "İçecek Çeşitleri": {
        "Ürünler": [
          "Kutu Kola",
          "Kutu Fanta",
          "Kutu Sprite",
          "Fuse Tea",
          "Meyve Suyu",
          "Şalgam",
          "Yayık Ayran",
          "Şişe Ayran",
          "Niğde Gazozu",
          "Soda",
          "Su"
        ],
        "Fiyatlar": [
          "50",
          "50",
          "50",
          "50",
          "50",
          "40",
          "40",
          "40",
          "40",
          "25",
          "15"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "08:00-02:00",
    "tel": "+90-537-780-3509",
    "payment": {},
    "description": ""
  },
  {
    "id": 10,
    "name": "Ofisser Cafe",
    "menu": {
      "Başlangıç Tost": {
        "Ürünler": [
          "Patates Tabağı",
          "Atıştırma Tabağı",
          "Kaşarlı Tost",
          "Karışık Tost"
        ],
        "Fiyatlar": [
          "100",
          "150",
          "140",
          "140"
        ]
      },
      "Sıcak Kahveler": {
        "Ürünler": [
          "Türk Kahvesi",
          "Menengiç Kahvesi",
          "Dibek Kahvesi",
          "Filtre Kahve",
          "Sütlü Filtre Kahve"
        ],
        "Fiyatlar": [
          "50",
          "50",
          "50",
          "80",
          "90"
        ]
      },
      "Çaylar & Oralet Çeşitleri": {
        "Ürünler": [
          "Çay",
          "Fincay Çay",
          "Maymun",
          "Prenses",
          "Karadut",
          "Muz",
          "Kuşburnu",
          "Elma",
          "Limon",
          "Tarçın",
          "Nane Limon",
          "Portakal",
          "Kivi"
        ],
        "Fiyatlar": [
          "15",
          "25",
          "30",
          "30",
          "20",
          "20",
          "20",
          "20",
          "20",
          "20",
          "20",
          "20",
          "20"
        ]
      },
      "Milkshake's": {
        "Ürünler": [
          "Çikolata",
          "Çilek",
          "Muz",
          "Vanilya",
          "Karamel"
        ],
        "Fiyatlar": [
          "100",
          "100",
          "100",
          "100",
          "100"
        ]
      },
      "Frozen's": {
        "Ürünler": [
          "Çilek ",
          "Kavun",
          "Muz",
          "Şeftali",
          "Karadut Rüyası",
          "Sürpriz",
          "Mevsim Meyvesi"
        ],
        "Fiyatlar": [
          "100",
          "100",
          "100",
          "100",
          "100",
          "100",
          "100"
        ]
      },
      "Bitki Çayları": {
        "Ürünler": [
          "Ada Çayı",
          "Kış Çayı",
          "Ihlamur",
          "Papatya Çayı",
          "Hibiskus Çayı"
        ],
        "Fiyatlar": [
          "80",
          "80",
          "80",
          "80",
          "80"
        ]
      },
      "Alternatif Tatlar": {
        "Ürünler": [
          "Sıcak Çikolata",
          "Sahlep"
        ],
        "Fiyatlar": [
          "80",
          "70"
        ]
      },
      "Oyunlar": {
        "Ürünler": [
          "Bilardo",
          "Masa Tenisi",
          "PlayStation"
        ],
        "Fiyatlar": [
          "120",
          "80",
          "80"
        ]
      },
      "İçecekler": {
        "Ürünler": [
          "Kola",
          "Fanta",
          "Sprite",
          "İce Tea",
          "Soda",
          "Meyveli Soda",
          "İtalyan Soda",
          "Churchill",
          "Ayran",
          "Su",
          "Limonata"
        ],
        "Fiyatlar": [
          "50",
          "50",
          "50",
          "50",
          "25",
          "30",
          "50",
          "50",
          "30",
          "15",
          "70"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "10:00-02:00",
    "tel": "+90-539-747-2146",
    "payment": {},
    "description": ""
  },
  {
    "id": 11,
    "name": "Ou La La",
    "menu": {
      "Menü": {
        "Ürünler": [
          "Sucuk Ekmek (Atom=150TL)",
          "Köfte Ekmek (Yoğurt= +50₺)",
          "Ciğer Ekmek (Şambali= 60₺)",
          "Çile Fasülye (Pilav=70₺)",
          "Kasap Köfte "
        ],
        "Fiyatlar": [
          "130",
          "150",
          "180",
          "90",
          "220"
        ],
        "Bilgi": {}
      }
    },
    "status": "Açık",
    "open_hours": "08:00-21:00",
    "tel": "+90-537-386-8994",
    "payment": {},
    "description": "Paket servis 2 sipariş ve üzerinde yapılmaktadır."
  },
  {
    "id": 12,
    "name": "Rodones Coffee",
    "menu": {
      "Klasikler": {
        "Ürünler": [
          "Espresso",
          "Double Espresso",
          "Americano",
          "Filtre Kahve",
          "Coffe Latte",
          "Cappucino",
          "Lungo",
          "Cortado",
          "Flat White",
          "Mocha",
          "Caramel Macchiato",
          "Macchiato",
          "White Mocha",
          "Lotus Latte",
          "Pumpkin Spice Latte",
          "Cookie Caramel Latte",
          "Almond Latte",
          "Chai Tea Latte",
          "Salted Caramel Latte",
          "Sahlep",
          "Sıcak Çikolata",
          "Sıcak Beyaz Çikolata",
          "Türk Kahvesi",
          "Demleme Çay",
          "Fincan Çay",
          "Take Away Çay",
          "Bitki Çayları"
        ],
        "Fiyatlar": [
          "75",
          "85",
          "100",
          "100",
          "110",
          "105",
          "90",
          "105",
          "110",
          "130",
          "130",
          "115",
          "130",
          "145",
          "135",
          "135",
          "135",
          "145",
          "135",
          "125",
          "120",
          "120",
          "70",
          "25",
          "40",
          "80",
          "80"
        ]
      },
      "3RD Wave Nitelikli Kahveler": {
        "Ürünler": [
          "V60",
          "Chemex"
        ],
        "Fiyatlar": [
          "135",
          "140"
        ]
      },
      "Soğuk Klasikler": {
        "Ürünler": [
          "Ice Americano",
          "Cold Brew",
          "Ice Latte",
          "Ice Mocha",
          "Ice Frappe",
          "Ice Caramel Mocha",
          "Ice Caramel Macchiato",
          "Ice Flat White",
          "Ice Lotus Latte"
        ],
        "Fiyatlar": [
          "110",
          "120",
          "120",
          "140",
          "135",
          "150",
          "140",
          "120",
          "155"
        ]
      },
      "Rodones'e Özel": {
        "Ürünler": [
          "Rodones Special Sıcak",
          "Rodones Special Sicak"
        ],
        "Fiyatlar": [
          "150",
          "165"
        ]
      },
      "Moco Cocktail": {
        "Ürünler": [
          "Cool Lime",
          "Berry Hibiscus",
          "Hibiscus Mint Tea",
          "Lavender Lemonade",
          "Karpuz ve Lychee Lemonade",
          "Chile Lemonade",
          "Hypnos",
          "Virgin Mojito (Alkolsüz)"
        ],
        "Fiyatlar": [
          "125",
          "125",
          "125",
          "125",
          "125",
          "125",
          "130",
          "150"
        ]
      },
      "Soğuk İçecekler": {
        "Ürünler": [
          "Su",
          "Soda",
          "Meyveli Soda",
          "Churchill",
          "Cam Şişe Gazoz",
          "Cola, Fanta, Sprite, Cappy",
          "Limonata",
          "Redbull"
        ],
        "Fiyatlar": [
          "30",
          "45",
          "50",
          "75",
          "55",
          "70",
          "85",
          "100"
        ]
      },
      "Frozen Çeşitleri": {
        "Ürünler": [
          "Çilek",
          "Karadut",
          "Mango",
          "Karpuz",
          "Kavun"
        ],
        "Fiyatlar": [
          "145",
          "145",
          "145",
          "145",
          "145"
        ]
      },
      "Milkshake Çeşitleri": {
        "Ürünler": [
          "Vanilya",
          "Çikolata",
          "Karamel",
          "Muz",
          "Çilek"
        ],
        "Fiyatlar": [
          "135",
          "135",
          "135",
          "135",
          "135"
        ]
      },
      "Atıştırmalık": {
        "Ürünler": [
          "Şans Kurabiyesi",
          "Tahıl Bar",
          "Selanik Gevreği",
          "Bardak Cookie Çeşitleri",
          "Glutensiz Bardak Cookie"
        ],
        "Fiyatlar": [
          "10",
          "35",
          "85",
          "100",
          "110"
        ]
      },
      "Sandviçler": {
        "Ürünler": [
          "Kruvasan",
          "Cheesewich",
          "Hindi Füme Sandviç",
          "Meat & Cheese Sandviç",
          "Roast Beef Sandviç"
        ],
        "Fiyatlar": [
          "110",
          "115",
          "130",
          "145",
          "160"
        ]
      },
      "Muffin & Cookie": {
        "Ürünler": [
          "Çikolata Cookie",
          "Çikolatalı Muffin",
          "Dilim Kek ",
          "Cookie Tart"
        ],
        "Fiyatlar": [
          "80",
          "90",
          "90",
          "105"
        ]
      },
      "Pasta & Cheesecake": {
        "Ürünler": [
          "Makaron",
          "Tiramusu",
          "Mozaik Pasta",
          "Magnolia",
          "Limonlu Cheesecake",
          "Frambuazlı Cheescake",
          "San Sebastian Cheesecake",
          "Lotus Cheesecake",
          "Baklava Cheesecake",
          "Yanık Karamel Tart",
          "Çikolatalı Brownie",
          "Krokan Cheesecake",
          "Mono Frambuaz Pasta",
          "Ananas Badem Pasta"
        ],
        "Fiyatlar": [
          "35",
          "115",
          "120",
          "125",
          "135",
          "135",
          "140",
          "145",
          "150",
          "150",
          "150",
          "160",
          "160",
          "160"
        ]
      },
      "Ürün İlaveleri": {
        "Ürünler": [
          "Laktozsuz süt",
          "Süt",
          "Bal",
          "Extra Shot",
          "Extra Şurup"
        ],
        "Fiyatlar": [
          "+15",
          "+25",
          "+15",
          "+25",
          "+25"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "10:00-01:00",
    "tel": "+90-232-765-9090",
    "payment": {},
    "description": ""
  },
  {
    "id": 13,
    "name": "Sırma Cafe",
    "menu": {
      "Makarnalar": {
        "Ürünler": [
          "Kremalı Tavuklu Makarna + Ayran",
          "Domates Soslu Makarna + Ayran",
          "Sade Kremalı Makarna + Ayran"
        ],
        "Fiyatlar": [
          "150",
          "150",
          "120"
        ]
      },
      "Pilav": {
        "Ürünler": [
          "Sade Pilav + Ayran",
          "Tavuklu Pilav + Ayran",
          "Ciğerli Pilav + Ayran",
          "1.5 Porsiyon Sade Pilav + Ayran",
          "1.5 Porsiyon Tavuklu Pilav + Ayran",
          "1.5 Porsiyon Ciğerli Pilav + Ayran",
          "Duble Sade Pilav + Ayran",
          "Duble Tavuklu Pilav + Ayran",
          "Duble Ciğerli Pilav + Ayran"
        ],
        "Fiyatlar": [
          "100",
          "120",
          "120",
          "130",
          "160",
          "160",
          "160",
          "200",
          "200"
        ]
      },
      "Ekmek Arası Izgara": {
        "Kategoriler": [
          "1/2 Ekmek",
          "3/4 Ekmek",
          "Tam Ekmek"
        ],
        "Ekmek Arası Balık (Sardalya)": [
          "80",
          "110",
          "140"
        ],
        "Ekmek Arası Kasap Sucuk": [
          "80",
          "110",
          "140"
        ],
        "Ekmek Arası Köfte + Ayran": [
          "100",
          "130",
          "160"
        ],
        "Ekmek Arası Izgara Tavuk + Ayran": [
          "100",
          "130",
          "160"
        ],
        "Ekmek Arası Sucuk Kaşar": [
          "100",
          "130",
          "160"
        ],
        "Ekmek Arası Sucuk Kaşar Yumurta": [
          "120",
          "150",
          "180"
        ],
        "Ekmek Arası Anne Patso": [
          "100",
          "130",
          "160"
        ],
        "Ekmek Arası Sosisli Anne Patso": [
          "130",
          "160",
          "200"
        ]
      },
      "Battalbey Çiğköfte": {
        "Ürünler": [
          "Dürüm Çiğköfte",
          "Duble Dürüm Çiğköfte",
          "Patatesli Çiğköfte (Patci)",
          "Doritoslu Çiğköfte",
          "Çiğköfte Mix (Doritos + Patates + Nugget)"
        ],
        "Fiyatlar": [
          "60",
          "90",
          "90",
          "75",
          "120"
        ]
      },
      "İçecekler": {
        "Ürünler": [
          "Coca Cola",
          "Fanta",
          "Sprite",
          "Ice Tea",
          "Ayran (Küçük)",
          "Ayran (Büyük)",
          "Şalgam",
          "Su",
          "Soda",
          "1 Lt Coca Cola",
          "2 Lt Coca Cola",
          "1 Lt Ayran"
        ],
        "Fiyatlar": [
          "45",
          "45",
          "45",
          "45",
          "20",
          "30",
          "45",
          "20",
          "30",
          "70",
          "90",
          "60"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "12:00-21:00",
    "tel": "+90-232-765-7545",
    "payment": {},
    "description": ""
  },
  {
    "id": 14,
    "name": "Sos Amanos Döner",
    "menu": {},
    "status": "Açık",
    "open_hours": "12:00-00:00",
    "tel": "+90-543-503-9660",
    "payment": {},
    "description": "Menüler düzenlenmektedir."
  },
  {
    "id": 15,
    "name": "Uğur Cafe",
    "menu": {
      "Ekmek Arası | Dürüm": {
        "Kategoriler": [
          "Yarım",
          "Üç Çeyrek ",
          "Tam / Çift Dürüm"
        ],
        "Karışık Sandviç": [
          "140",
          "190",
          "250"
        ],
        "Köfte Ekmek": [
          "100",
          "150",
          "200"
        ],
        "Tavuk Döner Ekmek": [
          "100",
          "150",
          "200"
        ],
        "Tavuk Döner Dürüm": [
          "110",
          "0",
          "120"
        ],
        "Patso": [
          "140",
          "190",
          "250"
        ],
        "Ayvalık Tostu": [
          "100                    ",
          "150",
          "200"
        ],
        "Sucuk Ekmek": [
          "100",
          "150",
          "200"
        ],
        "Sucuklu Yumurta": [
          "100",
          "150",
          "200"
        ],
        "Tüm Yarım Ekmek Arası Ürün + İçecek ve Patates Dahil": [
          "190",
          "0",
          "0"
        ]
      },
      "Izgaralar": {
        "Ürünler": [
          "Izgara Köfte",
          "Pilav üstü Tavuk Döner (120gr tavuk)"
        ],
        "Fiyatlar": [
          "180",
          "180"
        ]
      },
      "Makarnalar": {
        "Ürünler": [
          "Kremalı Tavuklu",
          "Kremalı Mantarlı Tavuklu",
          "Köri Soslu Tavuklu",
          "Soya Soslu Tavuklu",
          "Efsane Cafe Special",
          "Penne Arabiata Acılı",
          "Carbonara"
        ],
        "Fiyatlar": [
          "180",
          "180",
          "180",
          "180",
          "180",
          "180",
          "180"
        ],
        "Bilgi": {
          "Carbonara": "Sosis, salam, mantar"
        }
      },
      "Çiğ Köfteler": {
        "Ürünler": [
          "Battalbey Çiğ Köfte",
          "Patatesli Çiğ Köfte",
          "Doritoslu Çiğ Köfte"
        ],
        "Fiyatlar": [
          "65",
          "100",
          "85"
        ]
      },
      "Burgerler": {
        "Kategoriler": [
          "Tek",
          "Menü"
        ],
        "Hamburger": [
          "120",
          "200"
        ],
        "Cheeseburger": [
          "130",
          "210"
        ],
        "Chickenburger": [
          "120",
          "200"
        ],
        "Bilgi": {
          "Hamburger": "Menü içeriğinde içecek + orta boy patates",
          "Cheeseburger": "Menü içeriğinde içecek + orta boy patates",
          "Chickenburger": "Menü içeriğinde içecek + orta boy patates"
        }
      },
      "Aperatifler": {
        "Ürünler": [
          "Patates Kızartması",
          "Elma Dilim Patates"
        ],
        "Fiyatlar": [
          "130",
          "130"
        ]
      },
      "Waffle": {
        "Ürünler": [
          "İdeal Waffle",
          "Ekstra Soslar"
        ],
        "Fiyatlar": [
          "180",
          "5"
        ],
        "Bilgi": {
          "İdeal Waffle": "Muz, kivi, çilek, nutella veya beyaz çikolata üzerine çakıl taşı veya fındık",
          "Ekstra Soslar": "çikolata, karamel, beyaz çikolata ve frambuaz"
        }
      },
      "İçecekler": {
        "Ürünler": [
          "Coca-Cola, Sprite, Fanta, Ice-Tea Çeşitleri (kutu)",
          "Küçük Ayran (200 ml)",
          "Küçük Ayran (300 ml)",
          "Coca Cola (1 lt)",
          "Coca Cola (2 lt)",
          "Su",
          "Soda",
          "Çay",
          "Nescafe",
          "Türk Kahvesi"
        ],
        "Fiyatlar": [
          "45",
          "20",
          "30",
          "60",
          "80",
          "20",
          "30",
          "15",
          "30",
          "30"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "10:00-01:00",
    "tel": "+90-536-833-3488",
    "payment": {},
    "description": ""
  },
  {
    "id": 16,
    "name": "Dönerci Yaşar Usta",
    "menu": {
      "Et Döner": {
        "Ürünler": [
          "Yarım Ekmek (60gr)",
          "Yarım Ekmek (80gr)",
          "Yarım Ekmek (100gr)",
          "Tek Dürüm (60gr)",
          "Tek Dürüm (80gr)",
          "Tek Dürüm (100gr)",
          "Tam Ekmek",
          "Sade Porsiyon",
          "Üç Çeyrek",
          "Pilav Üstü",
          "İskender",
          "İskender 1.5"
        ],
        "Fiyatlar": [
          "145",
          "195",
          "245",
          "145",
          "195",
          "245",
          "250",
          "250",
          "220",
          "275",
          "275",
          "375"
        ]
      },
      "Tavuk Döner": {
        "Ürünler": [
          "Yarım Ekmek (80gr)",
          "Yarım Ekmek (100gr)",
          "Yarım Ekmek (150gr)",
          "Yarım Ekmek (200gr)",
          "Tek Dürüm (80gr)",
          "Tek Dürüm (100gr)",
          "Tek Dürüm (150gr)",
          "Tek Dürüm (200gr)",
          "Tam Ekmek",
          "Üç Çeyrek",
          "Sade Porsiyon",
          "İskender",
          "Pilav Üstü"
        ],
        "Fiyatlar": [
          "90",
          "115",
          "165",
          "220",
          "90",
          "115",
          "165",
          "220",
          "180",
          "150",
          "150",
          "185",
          "170"
        ]
      },
      "Sandviç": {
        "Ürünler": [
          "Karışık Sandviç"
        ],
        "Fiyatlar": [
          "150"
        ]
      },
      "Patates": {
        "Ürünler": [
          "Porsiyon Patates"
        ],
        "Fiyatlar": [
          "100"
        ]
      },
      "Fırsat Menüleri": {
        "Ürünler": [
          "Karışık Sandviç Kola Menü",
          "Karışık Sandviç Ayran Menü",
          "Tavuk Döner Kola Menü",
          "Tavuk Döner Ayran Menü",
          "Et Döner Kola Menü",
          "Et Döner Ayran Menü"
        ],
        "Fiyatlar": [
          "225",
          "215",
          "175",
          "170",
          "225",
          "215"
        ]
      },
      "İçecekler": {
        "Ürünler": [
          "Kutu içecekler",
          "Ayran",
          "Büyük Ayran",
          "Şişe Ayran",
          "Soda",
          "Su"
        ],
        "Fiyatlar": [
          "45",
          "20",
          "30",
          "25",
          "20",
          "15"
        ]
      }
    },
    "status": "Açık",
    "open_hours": "11:30-01:30",
    "tel": "+90-539-660-1552",
    "payment": {},
    "description": "Çift dürüm +10₺."
  }
]

export default data;