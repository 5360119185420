import React, { useEffect, useState } from "react";
import MenuDropdown from "./MenuDropdown";
import { createClient } from "@supabase/supabase-js";
import ShowQR from "./ShowQR";

import telephone from '../assets/telephone.svg';
import closed from '../assets/closed.svg';
import open from '../assets/open.svg';
import edit from '../assets/edit_2.svg';
import redirect from '../assets/redirect.svg';

import sodexo from '../assets/sodexo.png';
import pluxee from '../assets/pluxee.png';
import multinet from '../assets/multinet.png';

function Restaurant(props) {
  const [supabase, setSupabase] = useState(() => props.admin ? createClient(props.supabase_url, props.supabase_anon_key) : null);
  const [isOpen, setIsOpen] = React.useState(false);
  const menuKeys = Object.keys(props.menu);
  const [dbData, setDbData] = React.useState(props.db);

  const supabase_url = props.supabase_url;
  const supabase_anon_key = props.supabase_anon_key;

  const menuItems = menuKeys ? menuKeys.map((key) => {
    if (key === "Saat" || key === "Tel") return null;
    if ((Object.keys(props.menu[key])).includes("Kategoriler")) {
      return <MenuDropdown isOpen={isOpen} resName={props.name} name={key} table={true} admin={props.admin} submenu={props.menu[key]} db={props.db} id={props.id} supabase_url={supabase_url} supabase_anon_key={supabase_anon_key} />;
    }
    return <MenuDropdown isOpen={isOpen} resName={props.name} name={key} table={false} admin={props.admin} submenu={props.menu[key]} db={props.db} id={props.id} supabase_url={supabase_url} supabase_anon_key={supabase_anon_key} />;
  }) : null;

  const payments = dbData[props.id-1]["payment"]["Kartlar"] ? dbData[props.id-1]["payment"]["Kartlar"] : [];
  const cards = payments.map((payment) => {
    const renderedCards = new Set();
    if (payment === "sodexo" && !renderedCards.has("sodexo")) {
      renderedCards.add("sodexo");
      return <img src={sodexo} alt="Sodexo" className="h-4 md:h-7" />;
    }
    if (payment === "pluxee" && !renderedCards.has("pluxee")) {
      renderedCards.add("pluxee");
      return <img src={pluxee} alt="Pluxee" className="h-4 md:h-7" />;
    }
    if (payment === "multinet" && !renderedCards.has("multinet")) {
      renderedCards.add("multinet");
      return <img src={multinet} alt="Multinet" className="h-4 md:h-7" />;
    }
    return null;
  });
  const cardsDiv = cards.length > 0 ? <div className="flex gap-1 md:gap-2 bg-gray-400 justify-center items-center rounded-xl py-1 md:py-2 w-fit px-2 md:px-10">{cards.map((card, index) => (
    <React.Fragment key={index}>
      {card}
      {index < cards.length - 1 && <span className="mx-1">|</span>}
    </React.Fragment>
  ))}</div> : '';

  const handleEdit = async (event, editing_part) => {
    event.stopPropagation();
    const current_value = props.id ? props.db[props.id - 1][editing_part] : '';
    const editing_part_turkce = editing_part === "open_hours" ? "Açık saatler" : editing_part === "tel" ? "Telefon numarası" : editing_part === "name" ? "Restoran adı" : editing_part;

    const new_value = prompt(`${editing_part_turkce} için yeni değeri girin (şuanki değer: ${current_value}):`, current_value);
    if (editing_part === "tel") {
      const telRegex = /^\+90-\d{3}-\d{3}-\d{4}$/;
      if (!telRegex.test(new_value)) {
        alert("Telefon numarası +90-xxx-xxx-xxxx formatında olmalıdır.");
        return;
      }
    }
    if (editing_part === "open_hours") {
      const hoursRegex = /^\d{2}:\d{2}-\d{2}:\d{2}$/;
      if (!hoursRegex.test(new_value)) {
        alert("Açık saatler xx:xx-xx:xx formatında olmalıdır.");
        return;
      }
    }
    if (new_value && new_value.trim() !== "") {
      const updatedDbData = [...dbData];
      updatedDbData[props.id - 1][editing_part] = new_value;
      setDbData(updatedDbData);
      supabase.from("restaurants").update({ [editing_part]: new_value }).eq("id", props.id).then(({ data, error }) => {
        if (error) {
          console.error("Error updating restaurant:", error);
        } else {
          console.log("Restaurant updated successfully.");
        }
      });
    } else {
      console.log("Cancelled.");
    }
  };

  const [current_rest, setCurrentRest] = React.useState(true);
  React.useEffect(() => {
    if (props.subpage) {
      setIsOpen(true);
      setCurrentRest(false);
    }
  }, []);

  // ADMIN PANEL
  if (props.admin) {
    return (
      <div className="flex flex-col w-full">
        <button onClick={() => setIsOpen(!isOpen)} className="bg-menuBG text-white text-base border border-gray-700 flex items-center justify-between">
          <div className="md:px-5 px-3 py-3 flex md:gap-2 gap-1 items-center">
            <p className="md:text-lg text-sm">{isOpen ? '▾' : '▸'}</p>
            <p className="font-bold md:text-lg text-sm text-left inline-flex items-center gap-1">
              {dbData[props.id - 1]["name"]}
              <button onClick={(event) => handleEdit(event, "name")}>
                <img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" />
              </button>
            </p>
          </div>
          <div className="pr-3 flex md:gap-5 gap-2">
            <div className="flex">
              <button onClick={(event) => handleEdit(event, "open_hours")}>
                <img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" />
              </button>
              <p className="md:text-base text-sm whitespace-nowrap">{props.open_hours}</p>
            </div>
            <div className="flex items-center gap-0">
              <button onClick={(event) => handleEdit(event, "tel")}>
                <img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" />
              </button>
              <a href={`tel:${props.tel}`} target="_blank" rel="noreferrer">
                <img src={telephone} alt="Telephone" className="block h-5" />
              </a>
              <a className="hidden md:block text-[#1b931f] hover:underline hover:text-[#1b931f]" href={`tel:${props.tel}`} target="_blank" rel="noreferrer">{props.tel}</a>
            </div>
          </div>
        </button>
        <div className="w-full h-full" style={{ maxHeight: isOpen ? "100%" : "0", transition: "max-height 0.5s ease-in-out", overflow: "hidden" }}>
          <ul className="bg-menuBG text-white text-base md:px-7 px-3 pt-1 pb-3" style={{ opacity: isOpen ? "1" : "0", transition: "opacity 0.5s ease-in-out" }}>
            <div className="bg-menuBG pb-3">
              {cards}
            </div>
            {menuItems}
          </ul>
        </div>
      </div>
    );
  } else { // HOME PAGE
    return (
      <div className="flex flex-col w-full">
      <button onClick={() => setIsOpen(!isOpen)} className="bg-menuBG text-white text-base border border-gray-700 flex items-center justify-between">
        <div className="flex items-center max-w-[50%]">
        <div className="md:pl-5 pl-3 py-3 flex items-center md:gap-2 gap-1">
          <p className="md:text-lg text-sm">{isOpen ? '▾' : '▸'}</p>
          <p className="font-bold md:text-lg text-sm text-left inline items-center gap-1 md:gap-2 leading-normal">
          {props.name}
          {current_rest && <a className="h-3 md:h-5 inline-block leading-normal pl-1 md:pl-2" href={`/menu/${dbData[props.id-1]["name"].toLowerCase().replace(/\s+/g, '-')}`} target="_parent" rel="noreferrer" onClick={(event) => event.stopPropagation()}>
            <img src={redirect} alt="Redirect" className="h-3 md:h-5" />
          </a>}
          </p>
        </div>
        </div>
        <div className="pr-3 flex md:gap-3 gap-2 items-center">
        {props.status === "Kapalı" && <img src={closed} alt="Kapalı" className="block h-3 md:h-5" />}
        {props.status === "Açık" && <img src={open} alt="Açık" className="block h-3 md:h-5" />}
        <p className="md:text-base text-sm whitespace-nowrap">{props.open_hours}</p>
        <div className="flex items-center gap-0">
          <a className="block h-5 w-5" href={`tel:${props.tel}`} target="_blank" rel="noreferrer">
          <img src={telephone} alt="Telephone" className="block h-5 w-5" />
          </a>
          <a className="hidden md:block text-[#1b931f] hover:underline hover:text-[#1b931f]" href={`tel:${props.tel}`} target="_blank" rel="noreferrer" onClick={(event) => event.stopPropagation()}>{props.tel}</a>
        </div>
        </div>
      </button>
      <div className="w-full h-full" style={{ maxHeight: isOpen ? "100%" : "0", transition: "max-height 0.5s ease-in-out", overflow: "hidden" }}>
        <ul className="bg-menuBG text-white text-base md:px-7 px-3 pt-1 pb-3" style={{ opacity: isOpen ? "1" : "0", transition: "opacity 0.5s ease-in-out" }}>
        <div className="bg-menuBG py-2">
          <p className="italic font-semibold w-fit text-wrap text-xs md:text-sm pb-1">{dbData[props.id-1]["description"] ? dbData[props.id-1]["description"] : ''}</p>
          {cardsDiv}
        </div>
        {menuItems}
        </ul>
      </div>
      {props.subpage ? <ShowQR resName={props.name}/> :<></>}
      </div>
    );
  }
}

export default Restaurant;
