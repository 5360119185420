import React, { useState, useRef } from 'react';
import QRCode from "react-qr-code";
import { toPng } from 'html-to-image';

const ShowQR = (props) => {
  const [showQR, setShowQR] = useState(false);
  const qrRef = useRef();
  const currentUrl = window.location.href;

  const handleButtonClick = () => {
    setShowQR(!showQR);
  };

  const handleDownloadClick = () => {
    if (qrRef.current) {
      toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qr-code.png';
          link.click();
        })
        .catch((err) => {
          console.error('Failed to download QR code', err);
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-siteBG text-white p-5 rounded-md shadow-lg">
      <button 
        onClick={handleButtonClick} 
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sayfanın QR koduna erişmek için tıklayınız.
      </button>
      {showQR && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-md shadow-lg">
            <div className='flex items-center flex-col'>
              <h2 className="text-xl mb-4 text-black">{props.resName}</h2>
              <div ref={qrRef}>
                <QRCode value={currentUrl} />
              </div>
              <div className='flex justify-center gap-2'>
                <button 
                  onClick={handleDownloadClick} 
                  className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  QR Kodunu İndir
                </button>
                <button 
                onClick={handleButtonClick} 
                className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Kapat
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowQR;